.protable-role {
  .role-pro-table-search-filter-text {
    font-weight: bold;
    padding-top: 16px;
    margin-left: 24px;
  }

  .ant-form-item-label {
    flex-basis: fit-content !important;
  }

  .rolePage-action-icon {
    font-size: 20px;
    width: 24px;
    height: 24px;
  }
}

.rolePage-exclamation-icon {
  color: #faad14;
  margin-top: 8px;
  margin-right: 14px;
  font-size: 28px;
  font-weight: 300;
}

.rolePage-add-btn {
  display: flex;
  gap: 2px;
  padding: 4px 15px 4px 10px;
  .rolePage-add-icon {
    font-size: 22px;
  }
}
@primary-color: #03729F;