@import '~antd/lib/style/themes/default.less';

.RegisteredLayout {
  > aside.ant-layout-sider {
    height: 100vh;
    position: fixed;
    left: 0;
    color: white;
    white-space: nowrap;
    z-index: 6;
    background: #03729F;
    .ant-menu {
      background: transparent;
      .ant-menu-item-selected {
        background: #004662;
      }
    }
  }
  > section.ant-layout {
    color: inherit;
    height: 100vh;
    overflow-y: auto;
    > header.ant-layout-header {
      background-color: white;
      padding-left: 20px;
    }
    > .backdrop-content {
      display: none;
      width: 100%;
      height: 100%;
      background-color: rgba(66, 66, 66, 0.589);
      position: absolute;
      z-index: 5;
    }
    > .ant-layout-content {
      overflow: initial;
      margin: 24px;
    }
    transition: all 0.2s;
  }
  .env-tag {
    position: absolute;
    top: 0px;
    right: 1vw;
  }
  .site-layout {
    position: relative;
    flex: none;
  }
  .MainMenu{
    overflow-y: auto;
    max-height: 90vh;
  }
}

@media screen and (max-width: 400px) {
  .RegisteredLayout {
    > section.ant-layout {
      > header.ant-layout-header {
        padding-right: 24px;
      }
      > .ant-layout-content {
        margin: 1rem;
      }
    }
  }
}

.modal-tour-container {
  display: flex;
  gap: 20px;
  align-items: center;
  padding: 0 20px;
  .modal-tour-image-container {
    flex: 1;
  }
  @media screen and (max-width: 400px) {
    .modal-tour-image-container {
      display: none;
    }
  }
  .modal-tour-text-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    gap: 40px;
    text-align: left;
    flex: 1;
    .modal-tour-title {
      color: #03729f;
      font-weight: 400;
      font-size: 20px;
      letter-spacing: 0.1px;
    }
    .modal-tour-buttons-container {
      display: flex;
      justify-content: space-between;
    }
  }
}

@primary-color: #03729F;