.canvas-pdf{
    display: grid;
    place-items: center;
    margin-top: 2px;
    max-height: 100vh !important;
    overflow-y: auto;
}

.canvas-pdf>*{
    grid-row-start: 1;
    grid-column-start: 1;
}

.canvas-rect{
    z-index: 10;
}

.canvas-prevPage-btn{
    text-align: center;
}

.canvas-nextPage-btn{
    text-align: center;
}

.btn-group-pageinfo{
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: 0 12px;
    top: 8px;
    z-index: 1;
}

.collapse-review-logs{
    background: #FFFFFF;
    border: 1px solid #EEEEEE;
    box-shadow: 0px 5px 15px 2px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    position: absolute;
    z-index: 1;
    min-width: 98%;
    .ant-collapse-item > .ant-collapse-header > .ant-collapse-expand-icon > .anticon.anticon-right.ant-collapse-arrow >svg {
        transform: rotate(90deg) !important;
    }
      
    .ant-collapse-item-active > .ant-collapse-header > .ant-collapse-expand-icon > .anticon.anticon-right.ant-collapse-arrow >svg {
        transform: rotate(-90deg) !important;
    }
}

.scroll-collapse{
    max-height: 50vh;
    overflow-y: auto;

}
.canvas-pageNro{
    margin-top: 5px;
    text-align: center;

}

.receipt-page-icon{
    background: transparent;
    color: #03729f;
    font-size: 38px;
}
.collapse-log-alert{
    display: flex;
    gap: 2px;
}
.review-alert-icon{
    color: #FFB156;
    font-variation-settings:
    'FILL' 1,
    'wght' 400,
    'GRAD' 0;
    font-size: 18px
}
.pdf-canvas-item {
    position: absolute;
    background-color: transparent;
    border: 1px blue solid;
    box-sizing: content-box;
}

.pdf-canvas-item.pdf-canvas-item-active {
    border: 1px orangered solid;
}

.ant-collapse.ant-collapse-icon-position-start.ant-collapse-borderless.collapse-review-logs {
.ant-collapse-header {
        font-weight: bold;
      }
    
}
@primary-color: #03729F;