.protable-functionalities--wrapper__description {
  margin-bottom: 1rem;
  font-weight: 400;
  font-size: 16px;
  line-height: 21.82px;
}
.protable-functionalities--wrapper {
  .protable-functionalities {
    .ant-pro-card {
      border-radius: 10px;
    }
    .ant-table,
    .ant-table-content,
    .ant-table-thead {
      border-radius: 10px 10px 0 0;
    }

    .ant-pro-table-search-query-filter {
      margin-bottom: 0.5rem;
    }
    .ant-pro-query-filter {
      box-shadow: 0px 4px 20px 0px #0000001a;
      border-radius: 10px;
      .name-input {
        border-radius: 5px;
      }
    }
    .ant-pro-card-body {
      border: 1px solid #eeeeee;
      padding: 0px;
      box-shadow: 0px 4px 20px 0px #0000001a;
      .ant-table-thead
        > tr
        > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
        background-color: transparent;
      }
      .ant-table.ant-table-middle {
        .ant-table-thead > tr > th,
        .ant-table-tbody > tr > td {
          padding: 0.75rem 2rem;
        }
      }
    }
    .protable-functionalities__switch.ant-switch-checked {
      background-color: var(--indicator-done);
    }
  }
}

.functionalities-message-container {
  padding: 13px !important;
  .functionalities-error-icon{
    font-size: 44px;
    color: rgba(255, 177, 86, 1);
  }
  .GenericCard-container--information {
    gap: 10px;
  }
}

@primary-color: #03729F;