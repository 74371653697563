@import '../../../../globalVariables.less';

.help-popover-overlay {
  max-width: 85vw;
  max-height: 75vh;
  .ant-popover-content {
    max-height: 75vh;
    .ant-popover-inner {
      border-radius: 0.5rem;
      max-height: 75vh;
      overflow: auto;
      overflow-x: hidden;
      .ant-popover-inner-content {
        padding: 0;
      }
      scrollbar-width: auto;
      scrollbar-color: @SoftPink transparent;
    }
    /* Chrome, Edge, and Safari */
    .ant-popover-inner::-webkit-scrollbar {
      width: 5px;
      margin-left: 19px;
    }

    .ant-popover-inner::-webkit-scrollbar-track {
      background: white;
    }

    .ant-popover-inner::-webkit-scrollbar-thumb {
      background-color: @SoftPink;
      border-radius: 15px;
      border: none;
    }
    .help-video-container {
      border-radius: 10px;
      overflow: hidden;
      margin: 0;
      filter: grayscale(100%);
      cursor: pointer;
      
    }
    .help-popover {
      width: 30vw;
      max-width: 350px;
      padding: 2vh 2vw;
      text-align: center;
      display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: space-around;
        align-items: center;
      div {
        margin: 1vh 0;
        text-align: justify;
          text-justify: inter-word;
      }
    }
  }
  Button {
    text-align: center;
    width: 100%;
    margin-bottom: 1vh;
    font-weight: bold;
  }

  .secondary-button {
    color: #03729F !important;
    border-color: #03729F !important;

    &:hover {
      color: #1f88ab !important;
        border-color: #1f88ab !important;
    }
  }
}

    .help-button {
      display: flex !important;
      align-items: center;
      gap: 2px;
      cursor: pointer;
      user-select: none;
      height: 30%;
      text-align: center;
      .icon-help{
        font-size: 18px;
      }
    }

@primary-color: #03729F;