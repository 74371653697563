.protable-concept--wrapper {
  border-radius: 5px;
  .protable-concept--wrapper__description {
    margin-bottom: 1rem;
  }
  .protable-concept--wrapper2 {
    border: 1px solid #eeeeee;
    border-radius: 10px;
    .protable-concept {
      padding: 1rem;
      .ant-pro-table-search-query-filter {
        margin-bottom: 0.5rem;
      }
      .ant-pro-query-filter {
        box-shadow: 0px 4px 20px 0px #0000001a;
        border-radius: 10px;
        .name-input {
          border-radius: 5px;
        }
      }
      .ant-pro-card-body {
        border: 1px solid #eeeeee;
        padding: 0px;
        box-shadow: 0px 4px 20px 0px #0000001a;
        .ant-table-thead
          > tr
          > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
          background-color: transparent;
        }
        .ant-table.ant-table-middle {
          .ant-table-thead > tr > th,
          .ant-table-tbody > tr > td {
            padding: 0.75rem 2rem;
          }
        }
      }
      .ant-switch-checked {
        background-color: #3baa61;
      }
      .ant-form-item {
        margin: 0;
      }
      .ant-select-selection-item {
        text-align: left;
      }
    }
  }

  .protable-concept {
    .ant-table-thead
      > tr
      > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
      background-color: transparent !important;
    }
    .concept-action-icon{
      font-size: 20px;
      width: 24px;
      height: 24px;
    }
  }
  .ant-select-selector {
    border-radius: 5px !important;
    border-color: rgba(35, 35, 35, 0.15) !important;
    border-width: 1px !important;
  }

  .content-search-table {
    padding-right: 0px;
  }

  .ant-input-affix-wrapper {
    border-radius: 5px;
  }

  .concepts-pro-table-search-filter-text {
    font-weight: bold;
    padding-top: 16px;
    margin-left: 24px;
  }

  .ant-form-item-label {
    flex-basis: fit-content !important;
  }

  .buttonChangeProTableView {
    background-color: #d32210 !important;
    color: white !important;
    border-color: #d32210;
  }
  .buttonChangeProTableView:hover {
    opacity: 0.8;
    border-color: #d32210;
  }
}

.concept-new-button {
  text-align: end;
  margin-top: 8px;
  .btn-new {
    background-color: #d32210 !important;
    color: white !important;
    border-radius: 5px !important;
  }

  .btn-save.disabled {
    opacity: 0.5;
  }
}

.protable-concept {
  .concept-pro-table-search-filter-text {
    font-weight: bold;
    padding-top: 16px;
    margin-left: 24px;
  }

  .action-cell {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 2px;
  }

  .ant-form-item-label {
    flex-basis: fit-content !important;
  }
}

.modal-concept-rule {
  .ant-modal-header {
    padding: 16px;
  }
  .ant-modal-body {
    padding: 16px;
  }
}

.modal-concept {
  width: 362px !important;
  .ant-modal-content {
    padding: 16px;
    .ant-modal-header {
      padding: 0 0 16px 0;
      .ant-model-title {
        font-weight: 400;
        font-size: 16px;
        line-height: 21.82px;
      }
    }
    .ant-modal-body {
      padding: 0;
      .ant-pro-card.ant-pro-table-search.ant-pro-table-form.ant-pro-table-search-form {
        padding: 16px 0;
        .ant-select-selector,
        input {
          border-radius: 5px;
        }

        .ant-form-item {
          margin-bottom: 16px;

          .ant-col.ant-form-item-label {
            padding: 0 0 4px;
            > label {
              font-size: 13px;
              font-weight: 400;
              line-height: 17.73px;
            }
          }
        }

        .modal-concept-info {
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          align-items: center;
        }

        .modal-concept-info-text {
          display: flex;
          flex-direction: column;
          .modal-concept-info-text-description {
            margin-bottom: 0;
            font-weight: 400;
            font-size: 13px;
            line-height: 17.73px;
          }
        }

        .modal-concept-info-icon {
          display: flex;
          margin-right: 16px;
          font-size: 20px;
        }
      }
    }
  }

}
.ant-modal-footer {
  .save-btn {
    background-color: #d32210;
    border-color: transparent !important;
  }
  .save-btn:hover {
    opacity: 0.8;
  }
}

.concept-warning-icon{
  font-variation-settings:
    'FILL' 1,
    'wght' 400,
    'GRAD' 0;
  font-size: 16px;
  color: rgba(255, 177, 86, 1);
}
@primary-color: #03729F;