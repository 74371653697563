.ant-modal.modal-auth-link {
  .ant-modal-confirm-content {
    width: 100%;
    padding-right: 3rem;
    .ant-typography-copy .anticon {
      margin-top: 0;
    }
  }
}
.tenant-image-preview {
  min-width: 50vw;
  min-height: 50vh;
}


.protable-tenant{
  .tenant-pro-table-search-filter-text{
      font-weight: bold;
      padding-top: 16px;
      margin-left: 24px;
      
  }
  
  .ant-form-item-label{
      flex-basis: fit-content !important;
  }

  .tenant-add-btn {
    display: flex;
    gap: 2px;
    padding: 4px 15px 4px 10px;
    .tenant-add-icon {
      font-size: 22px;
    }
  }

  .tenant-action-icon {
    font-size: 20px;
    width: 24px;
    height: 24px;
  }
}

.tenant-upload-btn {
  display: flex;
  gap: 2px;
  padding: 4px 15px 4px 10px;
  .tenant-upload-icon {
    font-size: 22px;
    font-weight: 300;
  }
}
@primary-color: #03729F;