@font-face {
  font-family: 'Nunito';
  src: local('Nunito-VariableFont_wght') format("truetype"),
    url('../../../assets/fonts/Nunito-VariableFont_wght.ttf');
  font-weight: normal;
}
@font-face {
  font-family: 'Nunito';
  src: local('Nunito-VariableFont_wght') format("truetype"),
    url('../../../assets/fonts/Nunito-VariableFont_wght.ttf');
  font-weight: bold;
}
.DeviceUnavailableCheck-cover {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #EEF0F3 !important;
  align-items: center;
  z-index: 9999;
  display: block;
  text-align: center;
  justify-content: flex-start;
  padding: 10vh 5vw;
}
.DeviceUnavailableCheck-container {
  width: 90%;
  height: 70%;
  max-width: 288px;
  max-height: 403px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin: auto;
  color: #000 !important;
  font-family: 'Nunito';
  font-style: normal;
  line-height: normal;
  font-weight: normal;
  &--image {
    height: 25vh;
    margin-bottom: 64px;
  }
  &--title {
    text-align: center;
    font-size: 20px;
    margin: 0;
    margin-bottom: 32px;
  }
  &--subtitle1, &--subtitle2 {
    margin: 0;
    text-align: center;
    font-size: 16px;
  }
}
@primary-color: #03729F;