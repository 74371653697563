.timeline-container {
  margin-top: 30px;
  position: relative;
  .timeline-receipt-log {
    padding-top: 10px;
    min-height: 200px;
  }
  .timeline-receipt-card {
    position: absolute;
    top: 0;
    z-index: 1;
    background: #ffffff;
    border: 1px solid #eeeeee;
    box-shadow: 5px 5px 30px rgba(0, 0, 0, 20%);
    border-radius: 10px;
    min-width: 200px;
    max-width: 15vw;
    min-height: 165px;
    .ant-card-body {
      padding: 16px;
    }
    .timeline-receipt-card-icon {
      color: @primary-color;
      margin-bottom: 16px;
      font-weight: 300;
      left: -2px;
      position: relative;
    }

    .receipt-general-info-log{
      margin-bottom: 16px;
      p{
        margin-bottom: 0;
        font-size: 12px;
      }
    }

    @media screen and (max-width: 768px) {
      position: relative;
      margin-bottom: 20px;
      text-align: center;
      min-height: auto;
    }
  }

  .ant-badge-status-dot {
    width: 12px;
    height: 12px;
  }
  .ant-timeline.ant-timeline-label .ant-timeline-item-head {
    margin-left: 5px !important;
    top: 3.5px;
  }
  .ant-timeline.ant-timeline-label .ant-timeline-item-head,
  .ant-timeline.ant-timeline-label .ant-timeline-item-tail {
    left: 385px;
  }
  .ant-timeline.ant-timeline-label .ant-timeline-item-label {
    max-width: 367px;
  }
  .ant-timeline.ant-timeline-label
    .ant-timeline-item-left
    .ant-timeline-item-content {
    left: 381px;
    width: calc(65% - 14px);
    text-align: left;
  }

  .ant-card.ant-card-bordered.timeline-receipt-card {
    max-width: 220px !important;
  }
  
}

.receipt-log-date-range-input {
  @media screen and (min-width: 768px) {
    width: 300px !important;
    display: flex;
  }
}

@media screen and (max-width: 768px) {
  .timeline-container {
    .ant-timeline.ant-timeline-label .ant-timeline-item-head,
    .ant-timeline.ant-timeline-label .ant-timeline-item-tail {
      left: 35%;
    }
    .ant-timeline.ant-timeline-label .ant-timeline-item-label {
      width: calc(35% - 12px);
    }
    .ant-timeline.ant-timeline-label
      .ant-timeline-item-left
      .ant-timeline-item-content {
      left: calc(35% - 4px);
      width: calc(65% - 14px);

      text-align: left;
    }
  }
}

.ant-pro-card-logs {
  position: relative;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  margin-block: 0;
  margin-inline: 0;
  padding-block: 0;
  padding-inline: 0;
  background-color: #ffffff;
  border-radius: 4px;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 1.5714285714285714;
  list-style: none;
  min-height: 100vh;
}

.ant-pro-card-logs .ant-pro-card-body-logs {
  display: block;
  box-sizing: border-box;
  height: 100%;
  padding-inline: 24px;
  padding-block: 24px;
  padding-top: 0;
}
.collapse-logs{
  background: white;
  .ant-collapse-header{
    padding: 0 !important;
  }
}
.user-logs p{
  margin: 1em 0;
}
.user-mail-logs{
  margin: 0
}
.ant-collapse-item > .ant-collapse-header .anticon .ant-collapse-arrow {
  transform: rotate(90deg);
}

.ant-collapse-item-active > .ant-collapse-header .anticon .ant-collapse-arrow {
  transform: rotate(180deg);
}
.ant-pro-table-list-toolbar-container-logs {
    display: flex;
    justify-content: space-between;
    padding-block: 16px;
    padding-inline: 0;
    justify-content: 'space-between'
}
.receipt-back-link.receipt-back-link-logs{
  border: none;
  box-shadow: none;
}

.receipt-review-btn {
  float: right;
  background-color: #d32210 !important;
  color: white !important;
  border-radius: 5px !important;
  margin-left: 6px;
}

.receipt-review-btn.disabled-color-logReview{
  opacity: 0.5;
}

@primary-color: #03729F;