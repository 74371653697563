
.protable-product{
    .product-pro-table-search-filter-text{
        font-weight: bold;
        padding-top: 16px;
        margin-left: 24px;
        
    }
    
    .ant-form-item-label{
        flex-basis: fit-content !important;
    }

    .product-add-btn, .product-import-btn{
        display: flex;
        gap: 2px;
        padding: 4px 15px 4px 10px;
        .product-add-icon, .product-import-icon{
            font-size: 22px;
        }
    }

    .product-action-btn,.ant-btn-icon-only{
        width: 24px;
        height: 24px;
    }

    .product-action-icon{
        font-size: 20px;
    }
}
@primary-color: #03729F;