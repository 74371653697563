.termsandconditions {
  display: flex;
  flex-direction: column;
  background-color: lightgray;
  overflow-x: hidden;
  height: 100vh;
  &-header {
    display: flex;
    justify-content: center;
    height: 80px;
    position: relative;
    background-color: white;
    &-logo {
      flex-grow: 0;
      flex-basis: 1000px;
      flex-shrink: 1;
      margin: 0;
      padding: 24px;
      img {
        max-width: 100%;
        max-height: 100%;
      }
    }
  }
  &-body {
    margin: 20px 0;
    display: flex;
    justify-content: center;
    position: relative;
    &-main {
      flex-grow: 0;
      flex-basis: 1000px;
      flex-shrink: 1;
      margin: 0;
      padding: 24px;
      background-color: white;
      border-radius: 20px;
      img {
        max-width: 100%;
        max-height: 100%;
      }
    }
  }
  p {
    letter-spacing: 0.5pt !important;
  }
}

@primary-color: #03729F;