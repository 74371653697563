.messageRegExp {
  position: relative;
  display: flex;
  flex-direction: row;
  flex: 3;
  justify-content: flex-end;
  .textRegExp {
    text-align: left;
    font-size: 12px;
    margin-bottom: 0;
  }
}

.protable-user {
  .user-pro-table-search-filter-text {
    font-weight: bold;
    padding-top: 16px;
    margin-left: 24px;
  }

  .ant-form-item-label:first-of-type {
    flex-basis: fit-content !important;
  }

  .options-column {
    min-width: 140px;
  }

  .userPage-action-icon {
    font-size: 20px;
    width: 24px;
    height: 24px;
  }

  .userPage-add-btn {
    display: flex;
    gap: 2px;
    padding: 4px 15px 4px 10px;
    .userPage-add-icon {
      font-size: 22px;
    }
  }
}

.ant-modal-confirm-btns {
  .save-btn {
    background-color: #d32210;
    border-color: transparent !important;
  }
  .save-btn:hover {
    opacity: 0.8;
  }
}

.userPage-exclamation-icon {
  color: #faad14;
  margin-top: 8px;
  margin-right: 14px;
  font-size: 28px;
  font-weight: 300;
}

@primary-color: #03729F;