@import '../../globalVariables.less';

.tenantEditPageModal {
  width: 378px !important;
  .ant-modal-footer {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-start;
    gap: 10px;
    border: none;
    .ant-btn.ant-btn-primary{
      background-color: #d32210 !important;
      color: white !important;
      border-radius: 5px !important;
      border-color: transparent !important;
    }
    .ant-btn.ant-btn-primary:hover{
      opacity: 0.8;
    }
  }
  .ant-modal-body{
    padding: 16px 16px 0 16px ;

  }
  .ant-modal-content{
    border-radius: 10px;
  }
  .ant-modal-header{
    border-radius: 10px 10px 0 0;
  }
  .ant-modal-title{
    font-size: 20px;
    font-weight: 400;
  }
}

.tenantEditPage-form {
  .tenantEditPage-form__card {
    background-color: white;
    padding: 6px 16px 16px 16px;
    border-radius: 5px;
    box-shadow: @BoxShadow;
    max-width: 448px;
    margin: 0 auto;
    .ant-form-item {
      margin-bottom: 10px;
    }
    .link {
      text-decoration: underline;
    }
    .blue {
      color: @Blue;
    }
    .red {
      color: @Red;
    }
    .bold {
      font-weight: 500;
    }
    .ant-form-item.quota-details {
      height: 28px;
      margin-bottom: 4px !important;
      font-weight: 400;
      font-size: 13px;
      line-height: 17.73px;
      #label {
        font-weight: normal;
      }
    }
  }
  .tenantEditPage-form__buttons {
    display: flex;
    justify-content: center;

    .tenantEditPage-form__buttons--flex {
      display: flex;
      justify-content: space-between;
      width: 100%;
      gap: 10px;
      margin-bottom: 30px;
    }
  }
  .tenantEditPage-action-buttons-form{
    padding: 13px 0;
    border-radius: 5px;
    max-width: 448px;
    margin: 0 auto;
  }
  .tenantEditPage-card-form{
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  .tenantEditPage-down-organization{
    color: @Red;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
  }

  .tenantEditPage-submit-btn {
    background-color: #d32210 !important;
    color: white !important;
    border-radius: 5px !important;
    border-color: transparent !important;
  }

  .tenantEditPage-submit-btn:hover {
    opacity: 0.8;
  }

  .ant-form-item-label{
    height: 28px;
    padding: 0;
  }
  .ant-form-item-label > label{
    font-weight: 400!important;
    font-size: 13px!important;
    line-height: 17.73px!important;
  }
  .ant-form-item-required:not(.ant-form-item-required-mark-optional)::before{
    display: none !important;
  }


  .ant-input , .ant-select-selector{
    border-radius: 5px !important;
    border-color: rgba(35, 35, 35, 0.15);
    border-width: 1px; 
  }

  .ant-select-arrow{
    color: rgba(28, 27, 31, 1);
  }

  .tenantEditPage-down-organization > span{
    display: flex;
    flex-wrap: wrap;
    align-content: center;
  }    
  .tenantEdit-delete-icon{
    font-size: 18px;
  }
}

@primary-color: #03729F;